import type { FC } from "react";
import React from "react";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { useBloc } from "src/state/state";
import SanityCubit from "src/state/SanityCubit/SanityCubit";
import { CmsReadMoreItem } from "../../ReadMore/ReadMore";
import sanityQuery from "src/lib/sanityQuery";
import SanityContent from "../../SanityContent/SanityContent";
import SanityBlockContent, {
  SanityContentBlock
} from "../../SanityBlockContent/SanityBlockContent";
import { useSearchParams } from "react-router-dom";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";

const SanityDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();

  const [, { fetchFirst, firstElement: sanityResult }] = useBloc(SanityCubit, {
    create: () => new SanityCubit<CmsReadMoreItem>()
  });
  const [query] = useSearchParams();
  const slug = query.get("slug");

  React.useEffect(() => {
    if (!slug) return;
    void fetchFirst(sanityQuery.getBySlug(slug));
  }, [slug]);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const popupProps = React.useMemo(() => {
    const renderContent = (
      <SanityBlockContent
        blocks={sanityResult?.body as unknown as SanityContentBlock}
      />
    );

    return {
      onClose: onClose,
      title: sanityResult?.title ?? "",
      description: sanityResult?.description,
      mainContent: renderContent,
      fullPackageInsertLink: sanityResult?.fullPackageInsertLink
    };
  }, [sanityResult]);

  return (
    <InDialog
      popup
      title={sanityResult?.title ?? ""}
      onClose={onClose}
      returnUrl={returnUrl}
      simple
    >
      <div style={{ padding: "16px" }}>
        <SanityContent {...popupProps} />
      </div>
    </InDialog>
  );
};

export default SanityDialog;
